import React from "react";

export type Stateful<T> = {
  value: T;
  set: React.Dispatch<React.SetStateAction<T>>;
};

/** Wrapper for React.useState which returns a Stateful object instead of an array */
export function useStateful<T>(initialValue: T): Stateful<T> {
  const [value, setValue] = React.useState<T>(initialValue);
  return {
    value: value,
    set: setValue,
  };
}
