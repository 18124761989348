import React, { createContext, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { notifications } from "~/src/appGlobals";
import { Notification, isNotificationValid } from "~/src/contexts/UIContext/Notification";
import SnackbarDefault, { SnackbarDefaultProps } from "../../components/Snackbar/SnackbarDefault";
import { useStateful } from "../../hooks/useStateful";

export type ShowSnackbarProps = {
  undoFn?: () => void;
} & Omit<SnackbarDefaultProps, "onClose" | "t">;

export type UIContextData = {
  showSnackbar: (props: ShowSnackbarProps) => void;
  getGlobalNotifications: () => Notification[];
};

const UIContext = createContext<UIContextData>({} as UIContextData);

const UIProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { t } = useTranslation();
  const snackbarProps = useStateful<SnackbarDefaultProps[]>([]);
  const snackbars = useStateful<React.ReactElement[]>([]);

  const removeSnackbar = (props: ShowSnackbarProps) => {
    snackbarProps.set(snackbarProps.value.filter((_props) => _props.message !== props.message));
  };
  const showSnackbar = useCallback(
    (props: ShowSnackbarProps) => {
      const registeredSnackbar = snackbarProps.value.find(
        (_props) => _props.message === props.message,
      );
      if (registeredSnackbar === undefined) {
        snackbarProps.set(
          snackbarProps.value.concat([
            {
              ...props,
              t,
              onClose: () => {
                removeSnackbar(props);
              },
            },
          ]),
        );
      }
    },
    [snackbarProps.value, snackbarProps],
  );

  const getGlobalNotifications = useCallback(() => {
    return notifications.filter((notification) => isNotificationValid(notification));
  }, [notifications]);

  const uiContextData = {
    showSnackbar,
    getGlobalNotifications,
  };

  useEffect(() => {
    snackbars.set(snackbarProps.value.map((props) => SnackbarDefault(props)));
  }, [snackbarProps.value]);

  return (
    <UIContext.Provider value={uiContextData}>
      {props.children}
      {snackbars.value.map((snackbar, index) => {
        return <div key={index}>{snackbar}</div>;
      })}
    </UIContext.Provider>
  );
};

export { UIContext as default, UIProvider };
