import { uuidFromString } from "~/src/helpers/uuidHelpers";
import { JaktdagRegistrationDataElg } from "~/src/pages/Registrering/Jaktdag/RegistrerElg/ElgTypes";
import { JaktdagRegistrationDataHjort } from "~/src/pages/Registrering/Jaktdag/RegistrerHjort/HjortTypes";

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const parseErrors = (errorMessage: string): string => {
  const errorMessages = errorMessage.split("--");
  const formattedErrors: string[] = errorMessages.slice(1).map((msg) => {
    let form = msg.trim();
    if (form.endsWith(",")) {
      return form.slice(0, -1);
    } else return form;
  });
  return formattedErrors.join("\n");
};

export const uuidForJaktdag = (
  jaktdag: JaktdagRegistrationDataElg | JaktdagRegistrationDataHjort,
) => {
  return uuidFromString(
    `${jaktdag.Dato}-${jaktdag.Art}-${jaktdag.Jaktfelt || jaktdag.jaktomradeNavn}-${
      jaktdag.Jegernummer
    }`,
  );
};
