import { Link } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { SyntheticEvent, createContext, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import AppDataContext from "~/src/contexts/AppDataContext/DataProvider";
import { FormDataContext } from "~/src/contexts/FormDataContext/FormDataContext";
import { StorageContext } from "~/src/contexts/StorageContext/StorageProvider";
import {
  getOmradeForOmradekode,
  getOmradeForSingleFavoritt,
  getOmradeKeyForArt,
} from "~/src/helpers/omradeHelper";
import { CreateIndividRequest, Individ } from "../../../api/types";
import AlertDefault, { AlertVariant } from "../../../components/Alerts/AlertDefault";
import ButtonDefault from "../../../components/Button/ButtonDefault";
import Card from "../../../components/Card/Card";
import { ComboboxOption } from "../../../components/Form/Combobox/Combobox";
import UIContext from "../../../contexts/UIContext/UIProvider";
import { toErrorRecords } from "../../../helpers/formErrors";
import { ArtType } from "../../../helpers/types";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import useFormData from "../../../hooks/useFormData";
import { useStateful } from "../../../hooks/useStateful";
import { postIndividQuery, putIndividQuery } from "../../../react-query/queries";
import { isMissingOvervakData } from "../../Oversikt/helpers";
import { handleOfflineIndividCreateOrUpdate } from "../Fellingsinfo/helpers";
import DatoOgJaktomrade from "../Sections/DatoOgJaktomrade";
import KvitteringsKopi from "../Sections/EpostBekreftelse";
import MerkelappOgCwd from "../Sections/MerkelappOgCwd";
import OmSkuttDyr from "../Sections/OmSkuttDyr";
import StedfestFelling from "../Sections/StedfestFelling";
import { parseErrors } from "../helpers";
import { IndividData, useIndividSchema } from "../schemas/schema";

type IndividFormProps = {
  initialInfo: IndividData;
  mode: "create" | "update";
  jaktdagGodkjent?: boolean;
  errors?: Partial<Record<keyof Individ, string>>;
};

export const IndividFormDataContext = createContext<FormDataContext<IndividData> | undefined>(
  undefined,
);

const IndividForm = ({ initialInfo, mode, jaktdagGodkjent, errors }: IndividFormProps) => {
  const scrollTopRef = useRef<HTMLDivElement>(null);
  const [formErrors, setFormErrors] = useState<Partial<Record<keyof IndividData, string>>>(
    errors || {},
  );
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { schema } = useIndividSchema();
  const { showSnackbar } = useContext(UIContext);
  const appData = useContext(AppDataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const storage = useContext(StorageContext);

  const favorittomrade = getOmradeForSingleFavoritt(appData, initialInfo?.Art);
  if (favorittomrade) {
    initialInfo[getOmradeKeyForArt(initialInfo.Art)] = favorittomrade.Områdekode;
  }
  const [formData, setFormData, removeFormData, isDraft] = useFormData<IndividData>(initialInfo);

  const selectedJaktomradeNavn = getOmradeForOmradekode(
    formData?.Art,
    formData?.Villreinvald || formData?.Vald || formData?.Jaktfelt || "",
    appData,
  )?.Navn;

  const initialSelected = {
    id: formData?.Villreinvald || formData?.Vald || formData?.Jaktfelt || "",
    label: selectedJaktomradeNavn || "",
    data: {},
  } as ComboboxOption;
  const selectedJaktomrade = useStateful<ComboboxOption>(initialSelected);

  const navigateToOversiktSkutteDyr = () => {
    navigate("/oversikt", {
      state: { valgtOmradekode: selectedJaktomrade.value.id, activeTab: 1 },
    });
  };

  useEffect(() => {
    location.state = { routeProps: { valgtOmradekode: selectedJaktomrade.value.id } };
  }, [selectedJaktomrade.value]);

  // Submit
  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    const _formData = { ...formData };

    _formData.Jegernummer = appData.jegeropplysninger.data?.Jegernummer;
    _formData.RegistrertAv = appData.jegeropplysninger.data?.Navn;
    _formData.RegistrertDato = new Date().toISOString();

    if (!_formData.Id) {
      _formData.Id = uuid();
    }

    const result = schema.safeParse(_formData);
    if (result.success) {
      setFormErrors({});
    } else {
      const fieldErrors: Partial<Record<keyof IndividData, string>> = {};
      result.error.errors.forEach((error) => {
        fieldErrors[error.path[0] as keyof IndividData] = error.message;
      });
      setFormErrors(fieldErrors);
      scrollTopRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (!result.success) return;

    const storedIndivid = storage.get<IndividData>({ id: formData.Id }, "Individ");
    if (!appData.sync.isOnline.value || storedIndivid) {
      handleOfflineIndividCreateOrUpdate({ mode, formData: _formData, storage });

      if (!appData.sync.isOnline.value) {
        showSnackbar({
          message: t("sync.noNetworkWillSyncLater"),
        });
      }

      removeFormData();
      navigateToOversiktSkutteDyr();

      if (appData.sync.isOnline.value) {
        window.location.reload();
      }

      return;
    }

    if (mode === "create") {
      postIndividQuery(_formData as CreateIndividRequest)
        .then(async () => {
          await queryClient.invalidateQueries({ queryKey: ["individ", _formData.Id] });
          showSnackbar({
            message: t("pages.registrering.errors.registertSuksess"),
          });

          removeFormData();
          navigateToOversiktSkutteDyr();
        })
        .catch((error) => {
          setFormErrors(toErrorRecords<IndividData>(error));

          scrollTopRef.current?.scrollIntoView({
            behavior: "smooth",
          });

          const formattedErrors = parseErrors(error.message);
          showSnackbar({
            message: formattedErrors.length
              ? formattedErrors
              : t("pages.registrering.errors.noeGikkGalt"),
          });
        });
    } else if (mode === "update") {
      putIndividQuery(_formData as CreateIndividRequest)
        .then(async () => {
          await queryClient.invalidateQueries({ queryKey: ["individ", _formData.Id] });
          if (_formData.JaktdagId) {
            await queryClient.invalidateQueries({
              queryKey: ["jaktdag", { jaktdagId: _formData.JaktdagId }],
            });
          }
          showSnackbar({
            message: t("pages.registrering.errors.oppdatertSuksess"),
          });

          removeFormData();
          if (
            (initialInfo.Art == ArtType.Hjort || initialInfo.Art == ArtType.Elg) &&
            (initialInfo.Kjønn !== formData.Kjønn ||
              initialInfo.Alder !== formData.Alder ||
              initialInfo.Terreng !== formData.Terreng)
          ) {
            endreJaktdagDialog.show();
          } else {
            navigateToOversiktSkutteDyr();
          }
        })
        .catch((error) => {
          setFormErrors(toErrorRecords<IndividData>(error));

          scrollTopRef.current?.scrollIntoView({
            behavior: "smooth",
          });

          const formattedErrors = parseErrors(error.message);
          showSnackbar({
            message: formattedErrors.length
              ? formattedErrors
              : t("pages.registrering.errors.noeGikkGalt"),
          });
        });
    }
  };

  const endreJaktdagDialog = useConfirmationDialog({
    title: t("pages.registrering.dialogs.endreJaktdag.title"),
    message: t("pages.registrering.dialogs.endreJaktdag.message"),
    confirmButtonText: t("pages.registrering.dialogs.endreJaktdag.confirmButtonText"),
    onConfirm: () => navigate(`/oversikt/jaktdagdetaljer/${formData.JaktdagId}`),
    onCancel: () => {
      navigateToOversiktSkutteDyr();
    },
  });

  return (
    <IndividFormDataContext.Provider value={{ formData, setFormData, formErrors, setFormErrors }}>
      <div className="h-full">
        <form>
          <div ref={scrollTopRef} />
          <Card
            color="transparent"
            shadow={false}
            className="flex flex-col h-full p-2 pb-10 space-y-2"
          >
            {isMissingOvervakData(formData) && (
              <AlertDefault variant={AlertVariant.Warning} showIcon className="mx-4 mt-4">
                {t("pages.fellingsinfo.overvakningsomradeWarning")}
              </AlertDefault>
            )}
            {isDraft.value && (
              <AlertDefault variant={AlertVariant.Warning} showIcon className="mx-4 mt-4 flex-wrap">
                {t("pages.oversikt.hasIndividDraftDescription") + " "}
                <Link
                  onClick={() => {
                    removeFormData();
                    navigate(0);
                    showSnackbar({
                      title: t("pages.registrering.dialogs.draftRemoved.title"),
                      message: t("pages.registrering.dialogs.draftRemoved.message"),
                    });
                  }}
                >
                  {t("draft.deleteDraft")}
                </Link>
              </AlertDefault>
            )}

            {formErrors.Id && (
              <AlertDefault variant={AlertVariant.Error} showIcon className="mx-4 mt-4">
                {formErrors.Id}
              </AlertDefault>
            )}

            {[ArtType.Villrein, ArtType.Rådyr].includes(formData.Art) && (
              <DatoOgJaktomrade selectedState={selectedJaktomrade} />
            )}
            <OmSkuttDyr jaktdagGodkjent={jaktdagGodkjent} />
            <MerkelappOgCwd />
            <StedfestFelling />
            <KvitteringsKopi />
            <div className="mx-4 mb-10 pt-6">
              <ButtonDefault
                id="submit-form-registrer-individ"
                label="submit-form-registrer-individ-label"
                fullWidth
                type="button"
                onClick={(e) => handleSubmit(e)}
              >
                {t("actions.save")}
              </ButtonDefault>
            </div>
          </Card>
        </form>
        {endreJaktdagDialog.element}
      </div>
    </IndividFormDataContext.Provider>
  );
};

export default IndividForm;
