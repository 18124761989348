import Link from "@mui/material/Link";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { format, formatISO, parseISO } from "date-fns";
import {
  SyntheticEvent,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { IndividResponse, Jaktdag, JaktdagResponse } from "~/src/api/types";
import Counter from "~/src/components/Counter/Counter";
import { DialogAlignment } from "~/src/components/Dialog/DialogDefault";
import { ComboboxOption } from "~/src/components/Form/Combobox/Combobox";
import AppDataContext from "~/src/contexts/AppDataContext/DataProvider";
import { FormDataContext } from "~/src/contexts/FormDataContext/FormDataContext";
import { StorageContext } from "~/src/contexts/StorageContext/StorageProvider";
import { firstNonEmpty } from "~/src/helpers/filters";
import { toErrorRecords } from "~/src/helpers/formErrors";
import { ArtType } from "~/src/helpers/types";
import { useConfirmationDialog } from "~/src/hooks/useConfirmationDialog";
import { useDialog } from "~/src/hooks/useDialog";
import useFormData from "~/src/hooks/useFormData";
import { useStateful } from "~/src/hooks/useStateful";
import {
  getFormDataStorageKey,
  handleOfflineJaktdagCreateOrUpdate,
  removeJaktdagDraft,
} from "~/src/pages/Registrering/Jaktdag/common/helpers";
import { Terreng } from "~/src/pages/Registrering/types";
import { getJaktdagerQuery } from "~/src/react-query/queries";
import { jaktdataApi } from "../../../../appGlobals";
import { AccordionDefault } from "../../../../components/Accordion/AccordionDefault";
import AlertDefault, { AlertVariant } from "../../../../components/Alerts/AlertDefault";
import ButtonDefault, { ButtonLayout } from "../../../../components/Button/ButtonDefault";
import {
  dateFormatISO,
  dateFormatNorsk,
} from "../../../../components/CalendarPicker/CalendarConstants";
import CalendarPicker from "../../../../components/CalendarPicker/CalendarPicker";
import Card from "../../../../components/Card/Card";
import JaktomradePicker from "../../../../components/JaktomradePicker/JaktomradePicker";
import Text from "../../../../components/Text/Text";
import UIContext from "../../../../contexts/UIContext/UIProvider";
import { parseErrors, uuidForJaktdag } from "../../helpers";
import AntallJegereOgTimerJaktet from "../common/AntallJegereOgTimerJaktet";
import HeaderRight from "../common/HeaderRight";
import SkuttHelpText from "../common/SkuttHelpText";
import SuccessDialogButtons from "../common/SuccessDialogButtons";
import { RegistrerJaktdagProps } from "../common/types";
import { hjortFormValidation } from "./HjortFormValidation";
import {
  parseInitialData,
  setTerreng,
  sumSettOgSkuttHjort,
  toJaktdagRequestHjort,
} from "./HjortHelpers";
import {
  JaktdagRegistrationDataHjort,
  settHjortInnmarkDataKeys,
  settHjortUtmarkDataKeys,
  skuttHjortInnmarkDataKeys,
  skuttHjortUtmarkDataKeys,
} from "./HjortTypes";
import VelgTerreng from "./VelgTerreng";

export const JaktdagHjortFormDataContext = createContext<
  FormDataContext<JaktdagRegistrationDataHjort> | undefined
>(undefined);

export default function RegistrerHjort({ initialData }: RegistrerJaktdagProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbar } = useContext(UIContext);
  const appData = useContext(AppDataContext);
  const scrollTopRef = useRef<HTMLDivElement>(null);
  const isSubmitting = useStateful(false);
  const { t } = useTranslation();
  const fellingsRapportLevert = useStateful(false);
  const queryClient = useQueryClient();
  const storage = useContext(StorageContext);
  const eksisterendeJaktdag = useStateful<JaktdagResponse | undefined>(undefined);
  const mode = initialData?.Id ? "update" : "create";
  const initialFormData = parseInitialData(initialData, appData);
  const [formData, setFormData, removeFormData, isDraft] =
    useFormData<JaktdagRegistrationDataHjort>(initialFormData);
  const innmarkSettAccordionOpen = useStateful<boolean>(formData?.Innmark || false);
  const innmarkSkuttAccordionOpen = useStateful<boolean>(formData?.Innmark || false);
  const utmarkSettAccordionOpen = useStateful<boolean>(formData?.Utmark || false);
  const utmarkSkuttAccordionOpen = useStateful<boolean>(formData?.Utmark || false);

  const resetForm = () => {
    initialFormData && setFormData(initialFormData);
  };

  const date = useStateful<Date>((formData?.Dato && parseISO(formData.Dato)) || new Date());

  const initialSelected = {
    label: formData?.jaktomradeNavn || formData.jaktomradeNavn || "",
    id: formData?.Jaktfelt || formData.Jaktfelt || "",
    data: {},
  } || { label: "", id: "", data: {} };

  const selectedJaktomrade = useStateful<ComboboxOption>(initialSelected);

  const successDialog = useDialog({
    title: t("pages.registrering.dialogs.overforingSuccess.title"),
    message: t("pages.registrering.dialogs.overforingSuccess.message"),
    alignment: DialogAlignment.Center,
    buttonLayout: ButtonLayout.Vertical,
  });

  const jaktdagEksistererDialog = useConfirmationDialog({
    title: t("pages.registrering.dialogs.jaktdagEksisterer.title"),
    confirmButtonText: t("pages.registrering.dialogs.jaktdagEksisterer.confirmButtonText"),
    cancelButtonText: t("pages.registrering.dialogs.jaktdagEksisterer.cancelButtonText"),
  });

  const terrengHarRegistreringerDialog = useConfirmationDialog({
    confirmButtonText: t("pages.registrering.dialogs.terrengHarRegistreringer.confirmButtonText"),
  });

  const [formErrors, setFormErrors] = useState<
    Partial<Record<keyof JaktdagRegistrationDataHjort, string>>
  >({});
  const schema = hjortFormValidation(t, fellingsRapportLevert);
  const sumSettOgSkutt = useMemo(() => sumSettOgSkuttHjort(formData), [formData]);

  const eksisterendeJaktdager = useQuery({
    queryKey: [
      "jaktdager",
      {
        jaktomradeId: selectedJaktomrade.value.id,
        sesong: date.value.getFullYear(),
        artFilter: ["Hjort"],
        dateFilter: date.value,
        jegernummerFilter: appData.jegeropplysninger.data?.Jegernummer,
      },
    ],
    queryFn: getJaktdagerQuery,
    enabled: !!formData.Jaktfelt && !!date.value && !!appData.jegeropplysninger.data,
    staleTime: 0,
  });

  useEffect(() => {
    setFormData({
      ...formData,
      Dato: format(date.value, dateFormatISO),
      Jaktfelt: selectedJaktomrade.value?.id || formData?.Jaktfelt || "",
      jaktomradeNavn: selectedJaktomrade.value?.label || formData?.jaktomradeNavn || "",
    });
    eksisterendeJaktdager.refetch();
    location.state = { routeProps: { valgtOmradekode: selectedJaktomrade.value.id } };
  }, [selectedJaktomrade.value, date.value]);

  useEffect(() => {
    if (eksisterendeJaktdager.isSuccess && eksisterendeJaktdager.data !== undefined) {
      const matchingJaktdag =
        eksisterendeJaktdager.data.length > 0 && eksisterendeJaktdager.data[0];
      const deletedStoredJaktdager = storage
        .getAll<Jaktdag>("Jaktdag")
        .filter((jaktdag) => jaktdag.operation === "delete" && !jaktdag.errors);
      const jaktdagEksisterer =
        matchingJaktdag &&
        initialData?.Id != matchingJaktdag.Id &&
        appData.jegeropplysninger.data?.Jegernummer == matchingJaktdag.Jegernummer &&
        deletedStoredJaktdager.find((deleted) => deleted.data?.Id == matchingJaktdag.Id) ===
          undefined;
      if (jaktdagEksisterer) {
        eksisterendeJaktdag.set(matchingJaktdag);
      } else {
        eksisterendeJaktdag.set(undefined);
      }
    }
  }, [eksisterendeJaktdager.data, eksisterendeJaktdager.status, date.value]);

  useEffect(() => {
    eksisterendeJaktdag.value &&
      jaktdagEksistererDialog.show({
        message: t("pages.registrering.dialogs.jaktdagEksisterer.message", {
          dato: format(date.value, "d. MMM"),
          art: t("fauna.art.hjort.artsnavn").toLowerCase(),
          jaktomrade: formData.jaktomradeNavn,
        }),
        onCancel: () => {
          jaktdagEksistererDialog.close();
        },
        onConfirm: () => {
          navigate(`/registrering/jaktdag/${eksisterendeJaktdag.value?.Id}`);
          removeFormData();
        },
      });
  }, [eksisterendeJaktdag.value]);

  const counterErrorMessages = useMemo(() => {
    const settInnmark =
      firstNonEmpty(settHjortInnmarkDataKeys.map((field) => formErrors[field])) ||
      formErrors["AntallJegereInnmark"] ||
      formErrors["AntallTimerJaktetInnmark"];
    const settUtmark =
      firstNonEmpty(settHjortUtmarkDataKeys.map((field) => formErrors[field])) ||
      formErrors["AntallJegere"] ||
      formErrors["AntallTimerJaktet"];
    const skuttInnmark = firstNonEmpty(skuttHjortInnmarkDataKeys.map((field) => formErrors[field]));
    const skuttUtmark = firstNonEmpty(skuttHjortUtmarkDataKeys.map((field) => formErrors[field]));

    return { settInnmark, settUtmark, skuttInnmark, skuttUtmark };
  }, [formErrors]);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    const _formData = {
      ...formData,
      RegistrertAv: initialData?.RegistrertAv,
      Id: uuidForJaktdag(formData),
    };
    const result = schema.safeParse(_formData);

    if (!result.success) {
      const fieldErrors: Partial<Record<keyof JaktdagRegistrationDataHjort, string>> = {};
      result.error.errors.forEach((error) => {
        fieldErrors[error.path[0] as keyof JaktdagRegistrationDataHjort] = error.message;
      });
      setFormErrors(fieldErrors);
      scrollTopRef.current?.scrollIntoView({ behavior: "smooth" });
      if (!result.success) return;
    }

    setFormErrors({});
    const requestMethodFor =
      mode === "update"
        ? jaktdataApi.putJaktdag.bind(jaktdataApi)
        : jaktdataApi.postJaktdag.bind(jaktdataApi);

    isSubmitting.set(true);

    const skotneDyr = initialData?.Individer?.map((individ) => {
      return {
        ...individ,
        Dato: formatISO(date.value, { representation: "date" }),
        RegistrertDato: formatISO(date.value, { representation: "date" }),
        Jegernummer: formData.Jegernummer,
      };
    });

    try {
      const request = toJaktdagRequestHjort(
        formData,
        date.value,
        appData.jegeropplysninger.data?.Jegernummer,
        skotneDyr ?? undefined,
      );

      const storedJaktdag = storage.get<JaktdagResponse>({ id: request.Id }, "Jaktdag");
      if (!appData.sync.isOnline.value || storedJaktdag) {
        const jaktdagToStore = request as Jaktdag;
        jaktdagToStore.RegistrertAv = _formData.RegistrertAv;
        handleOfflineJaktdagCreateOrUpdate({
          mode,
          jaktdagToStore,
          appData,
          storage,
        });

        if (!appData.sync.isOnline.value) {
          showSnackbar({
            message: t("sync.noNetworkWillSyncLater"),
          });
        }

        successDialog.show({
          buttons: <SuccessDialogButtons valgtOmradekode={selectedJaktomrade.value.id} />,
        });

        removeFormData();

        return;
      }

      requestMethodFor(request)
        .then(() => {
          const individQueries: Promise<IndividResponse>[] = [];
          Promise.all([
            jaktdataApi.getJaktdag(request.Id),
            jaktdataApi
              .getSkutteDyr(date.value.getFullYear(), selectedJaktomrade.value.id)
              .then((individer) => {
                individer.forEach((individ) => {
                  individQueries.push(jaktdataApi.getIndivid(individ.Id || ""));
                });
              }),
            queryClient.invalidateQueries({
              queryKey: ["jaktdag", { jaktdagId: initialData?.Id }],
            }),
          ]).then(async () => {
            await Promise.all(individQueries);
            removeFormData();
            successDialog.show({
              buttons: <SuccessDialogButtons valgtOmradekode={selectedJaktomrade.value.id} />,
            });
          });
        })
        .catch((error) => {
          scrollTopRef.current?.scrollIntoView({ behavior: "smooth" });

          setFormErrors(toErrorRecords<JaktdagRegistrationDataHjort>(error));
          const formattedErrors = parseErrors(error.message);
          showSnackbar({
            message: formattedErrors.length
              ? formattedErrors
              : t("pages.registrering.errors.noeGikkGalt"),
          });
        })
        .finally(() => isSubmitting.set(false));
    } catch (e) {
      isSubmitting.set(false);
      resetForm();
      if (e instanceof Error) {
        if (e.message === "CanNotDelete") {
          showSnackbar({ message: t("pages.registrering.errors.canNotDelete") });
        } else {
          showSnackbar({ message: e.message });
        }
      }
    }
  };

  // Do counter validation on counter change
  useEffect(() => {
    const handler = setTimeout(() => {
      // Cleanup function to clear the timeout if the effect is called again before the delay
      const result = schema.safeParse(formData);
      if (result.success) {
        setFormErrors({});
        return;
      }
      const fieldErrors: Partial<Record<keyof JaktdagRegistrationDataHjort, string>> = {};
      [
        ...settHjortInnmarkDataKeys,
        ...skuttHjortInnmarkDataKeys,
        ...settHjortUtmarkDataKeys,
        ...skuttHjortUtmarkDataKeys,
      ].forEach((field) => {
        fieldErrors[field] = result.error.errors.find((error) => error.path[0] === field)?.message;
      });
      setFormErrors({ ...formErrors, ...fieldErrors });
    }, 50); // Adjust the debounce delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [formData]);

  return (
    <JaktdagHjortFormDataContext.Provider
      value={{ formData, formErrors, setFormData, setFormErrors: setFormErrors }}
    >
      <div className="h-full pb-7">
        <div ref={scrollTopRef} />

        {fellingsRapportLevert.value && (
          <AlertDefault
            id="registreringFellingsrapportLevertAlert"
            variant={AlertVariant.Info}
            className="mt-4 mx-4"
          >
            {t("pages.registrering.alerts.fellingsrapportLevert")}
          </AlertDefault>
        )}
        {isDraft.value && !fellingsRapportLevert.value && (
          <AlertDefault variant={AlertVariant.Warning} showIcon className="mx-4 mt-4 flex-wrap">
            {t("pages.oversikt.hasJaktdagDraftDescription") + " "}
            <Link
              onClick={() => removeJaktdagDraft(getFormDataStorageKey(), navigate, showSnackbar)}
            >
              {t("draft.deleteDraft")}
            </Link>
          </AlertDefault>
        )}

        {formErrors.Id && (
          <AlertDefault variant={AlertVariant.Error} showIcon className="mx-4 mt-4">
            {formErrors.Id}
          </AlertDefault>
        )}

        <Card color="transparent" shadow={false} className="flex flex-col h-full p-2 space-y-2">
          <form>
            <AccordionDefault
              headerContent={
                <div className="flex justify-between w-full">
                  <div>{t("pages.registrerArt.datoOgJaktfelt")}</div>
                  <div className="text-sm w-32 mt-1 text-md-gray font-light text-opacity-70 text-ellipsis overflow-hidden text-nowrap whitespace-nowrap">
                    <div className=""></div>
                    {formData.jaktomradeNavn &&
                      date.value &&
                      format(date.value, dateFormatNorsk) + " " + formData.jaktomradeNavn}
                  </div>
                </div>
              }
              error={Boolean(
                formErrors.Jaktfelt || formErrors.AntallJegere || formErrors.AntallTimerJaktet,
              )}
            >
              <div className="flex flex-col justify-evenly p-2 space-y-5">
                <CalendarPicker
                  id="registrerArtDatePicker"
                  label={t("form.fields.date.label")}
                  date={date}
                />
                <JaktomradePicker
                  selectedState={selectedJaktomrade}
                  valgtArt={ArtType.Hjort}
                  onChange={async (selected) => {
                    setFormData({
                      ...formData,
                      Jaktfelt: selected?.id || "",
                      jaktomradeNavn: selected?.label || "",
                    });
                    setFormErrors({ ...formErrors, Jaktfelt: "" });
                    fellingsRapportLevert.set(selected?.data?.ÅpenForRegistreringHjort === false);
                    await queryClient.invalidateQueries({ queryKey: ["jaktdager"] });
                    await eksisterendeJaktdager.refetch();
                  }}
                  disabled={fellingsRapportLevert?.value}
                  defaultOmradekode={formData.Jaktfelt || initialData?.Jaktfelt || ""}
                  helperText={
                    initialData === undefined
                      ? t("form.fields.jaktfelt.helperText")
                      : t("form.fields.jaktfelt.helperTextRediger")
                  }
                  errorText={formErrors.Jaktfelt}
                />
                <VelgTerreng
                  innmark={!!formData.Innmark}
                  utmark={!!formData.Utmark}
                  setInnmark={(value) => {
                    setTerreng(
                      value,
                      Terreng.Innmark,
                      formData,
                      setFormData,
                      terrengHarRegistreringerDialog,
                      innmarkSkuttAccordionOpen,
                      innmarkSettAccordionOpen,
                    );
                  }}
                  setUtmark={(value) => {
                    setTerreng(
                      value,
                      Terreng.Utmark,
                      formData,
                      setFormData,
                      terrengHarRegistreringerDialog,
                      utmarkSkuttAccordionOpen,
                      utmarkSettAccordionOpen,
                    );
                  }}
                  errors={!!(formErrors.Innmark || formErrors.Utmark)}
                />
              </div>
            </AccordionDefault>
            <AccordionDefault
              isOpen={innmarkSettAccordionOpen}
              headerContent={
                <div className="flex flex-row w-full items-center">
                  {t("pages.registrerArt.sett")}
                  <div className="text-sm ml-8"> {t("pages.fellingsinfo.innmark")}</div>
                  {formData.Innmark && (
                    <HeaderRight label="Sum">
                      {sumSettOgSkutt.innmark.sett.sumSett || "-"}
                    </HeaderRight>
                  )}
                </div>
              }
              disabled={fellingsRapportLevert.value || !formData.Innmark}
              errorMessage={counterErrorMessages.settInnmark}
            >
              {!counterErrorMessages.settInnmark && (
                <Text variant="small">
                  {t("pages.registrerArt.settHelpText", {
                    art: t("fauna.art.hjort.artsnavn").toLowerCase(),
                  })}
                </Text>
              )}
              <div className="p-2 space-y-2">
                <AntallJegereOgTimerJaktet
                  formData={formData}
                  setFormData={setFormData}
                  errors={formErrors}
                  setErrors={setFormErrors}
                  disabled={fellingsRapportLevert.value}
                  innmark={true}
                />
                <Text variant="small" className="pt-8">
                  {t("pages.registrerArt.settHelpText", {
                    art: t("fauna.art.hjort.artsnavn").toLowerCase(),
                  })}
                </Text>
                {settHjortInnmarkDataKeys.map((dyrType) => {
                  return (
                    <Counter
                      key={dyrType}
                      showErrorHelperText={false}
                      id={dyrType}
                      label={t(`pages.registrerArt.hjort.sett.${dyrType}`)}
                      error={formErrors[dyrType]}
                      value={formData[dyrType] || 0}
                      onCountChanged={(count) => {
                        const _formData = { ...formData };
                        _formData[dyrType] = count;
                        setFormData({ ..._formData });
                      }}
                    />
                  );
                })}
              </div>
            </AccordionDefault>
            <AccordionDefault
              isOpen={innmarkSkuttAccordionOpen}
              headerContent={
                <div className="flex flex-row w-full items-center">
                  {t("pages.registrerArt.skutt")}
                  <div className="text-sm ml-5"> {t("pages.fellingsinfo.innmark")}</div>
                  {formData.Innmark && (
                    <HeaderRight label="Sum">
                      {sumSettOgSkutt.innmark.skutt.sumSkutt || "-"}
                    </HeaderRight>
                  )}
                </div>
              }
              disabled={fellingsRapportLevert.value || !formData.Innmark}
              errorMessage={counterErrorMessages.skuttInnmark}
            >
              {!counterErrorMessages.skuttInnmark && <SkuttHelpText />}{" "}
              <div className="p-2 space-y-2">
                {skuttHjortInnmarkDataKeys.map((dyrType) => {
                  return (
                    <Counter
                      key={dyrType}
                      showErrorHelperText={false}
                      id={dyrType}
                      label={t(`pages.registrerArt.hjort.skutt.${dyrType}`)}
                      error={formErrors[dyrType]}
                      value={formData[dyrType]}
                      onCountChanged={(count) => {
                        const _formData = { ...formData };
                        _formData[dyrType] = count;
                        setFormData({ ..._formData });
                      }}
                    />
                  );
                })}
              </div>
            </AccordionDefault>
            <AccordionDefault
              isOpen={utmarkSettAccordionOpen}
              headerContent={
                <div className="flex flex-row w-full items-center">
                  {t("pages.registrerArt.sett")}
                  <div className="text-sm ml-8"> {t("pages.fellingsinfo.utmark")}</div>
                  {formData.Utmark && (
                    <HeaderRight label="Sum">
                      {sumSettOgSkutt.utmark.sett.sumSett || "-"}
                    </HeaderRight>
                  )}
                </div>
              }
              disabled={fellingsRapportLevert.value || !formData.Utmark}
              errorMessage={counterErrorMessages.settUtmark}
            >
              {!counterErrorMessages.settUtmark && (
                <Text variant="small">
                  {t("pages.registrerArt.settHelpText", {
                    art: t("fauna.art.hjort.artsnavn").toLowerCase(),
                  })}
                </Text>
              )}
              <div className="p-2 space-y-2">
                <AntallJegereOgTimerJaktet
                  formData={formData}
                  setFormData={setFormData}
                  errors={formErrors}
                  setErrors={setFormErrors}
                  disabled={fellingsRapportLevert.value}
                />
                <Text variant="small" className="pt-8">
                  {t("pages.registrerArt.settHelpText", {
                    art: t("fauna.art.hjort.artsnavn").toLowerCase(),
                  })}
                </Text>
                {settHjortUtmarkDataKeys.map((dyrType) => {
                  return (
                    <Counter
                      key={dyrType}
                      showErrorHelperText={false}
                      id={dyrType}
                      label={t(`pages.registrerArt.hjort.sett.${dyrType}`)}
                      error={formErrors[dyrType]}
                      value={formData[dyrType] || 0}
                      onCountChanged={(count) => {
                        const _formData = { ...formData };
                        _formData[dyrType] = count;
                        setFormData({ ..._formData });
                      }}
                    />
                  );
                })}
              </div>
            </AccordionDefault>
            <AccordionDefault
              isOpen={utmarkSkuttAccordionOpen}
              headerContent={
                <div className="flex flex-row w-full items-center">
                  {t("pages.registrerArt.skutt")}
                  <div className="text-sm ml-5"> {t("pages.fellingsinfo.utmark")}</div>
                  {formData.Utmark && (
                    <HeaderRight label="Sum">
                      {sumSettOgSkutt.utmark.skutt.sumSkutt || "-"}
                    </HeaderRight>
                  )}
                </div>
              }
              disabled={fellingsRapportLevert.value || !formData.Utmark}
              errorMessage={counterErrorMessages.skuttUtmark}
            >
              {!counterErrorMessages.skuttUtmark && <SkuttHelpText />}
              <div className="p-2 space-y-2">
                {skuttHjortUtmarkDataKeys.map((dyrType) => {
                  return (
                    <Counter
                      key={dyrType}
                      showErrorHelperText={false}
                      id={dyrType}
                      label={t(`pages.registrerArt.hjort.skutt.${dyrType}`)}
                      error={formErrors[dyrType]}
                      value={formData[dyrType]}
                      onCountChanged={(count) => {
                        const _formData = { ...formData };
                        _formData[dyrType] = count;
                        setFormData({ ..._formData });
                      }}
                    />
                  );
                })}
              </div>
            </AccordionDefault>
            <ButtonDefault
              className="w-full mt-5 mb-5"
              containerClassName="flex justify-center"
              id="registrerHjortLagreButton"
              label={t("actions.save")}
              type="button"
              onClick={(e) => handleSubmit(e)}
              loading={isSubmitting.value}
              disabled={fellingsRapportLevert.value || !!eksisterendeJaktdag.value}
            />
          </form>
          {successDialog.element}
          {jaktdagEksistererDialog.element}
          {terrengHarRegistreringerDialog.element}
        </Card>
      </div>
    </JaktdagHjortFormDataContext.Provider>
  );
}
