import {
  Accordion as MUIAccordion,
  AccordionBody as MUIAccordionBody,
  AccordionHeader as MUIAccordionHeader,
} from "@material-tailwind/react";
import { type PropsWithChildren, useEffect } from "react";
import { AccordionIcon } from "../../helpers/componentHelpers";
import { Stateful, useStateful } from "../../hooks/useStateful";

export type AccordionDefaultProps = PropsWithChildren & {
  headerContent: string | React.ReactNode;
  defaultOpen?: boolean;
  isOpen?: Stateful<boolean>;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
};
export function AccordionDefault({
  headerContent,
  children,
  defaultOpen = true,
  isOpen = undefined,
  disabled = false,
  errorMessage,
  error = false,
}: AccordionDefaultProps): JSX.Element {
  const _isOpen = useStateful<boolean>(defaultOpen);

  useEffect(() => {
    if (isOpen !== undefined && isOpen.value != _isOpen.value) {
      _isOpen.set(isOpen?.value);
    }
  }, [isOpen, isOpen?.value]);

  useEffect(() => {
    const debounceSetOuter = setTimeout(() => {
      if (isOpen !== undefined && isOpen.value != _isOpen.value) {
        isOpen.set(_isOpen.value);
      }
    }, 50);
    return () => {
      clearTimeout(debounceSetOuter);
    };
  }, [_isOpen.value]);

  const headerClassName = `pb-1 mb-2 ${
    _isOpen.value ? "text-md-primary hover:text-md-primary border-md-primary" : ""
  } ${(errorMessage || error) && !disabled ? "border-md-error-red border-b-2" : ""}`;

  useEffect(() => {
    if (errorMessage || error) {
      _isOpen.set(true);
    }
  }, [errorMessage, error]);

  return (
    <MUIAccordion
      open={_isOpen.value && !disabled}
      disabled={disabled}
      icon={<AccordionIcon isOpen={_isOpen.value && !disabled} />}
    >
      <MUIAccordionHeader onClick={() => _isOpen.set(!_isOpen.value)} className={headerClassName}>
        <div className="mx-2 w-full">{headerContent}</div>
      </MUIAccordionHeader>
      <MUIAccordionBody className="flex flex-col justify-evenly p-2 space-y-2">
        {(error || errorMessage) && (
          <div className="text-md-error-red font-semibold">{errorMessage}</div>
        )}
        {children}
      </MUIAccordionBody>
    </MUIAccordion>
  );
}
