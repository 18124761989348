import { faCircleUser, faEnvelope, faPencil, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, List, ListItemPrefix, Typography } from "@material-tailwind/react";
import { Add, Logout } from "@mui/icons-material";
import { Alert, IconButton, ListItem } from "@mui/material";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AlertState } from "~/src/components/Alerts/AlertDefault";
import WarningAlert from "~/src/components/Alerts/WarningAlert";
import { StorageContext } from "~/src/contexts/StorageContext/StorageProvider";
import UIContext from "~/src/contexts/UIContext/UIProvider";
import { useStateful } from "~/src/hooks/useStateful";
import { Favorittområde, Område } from "../../../api/types";
import InfoAlert from "../../../components/Alerts/InfoAlert";
import ButtonDefault, { ButtonVariant } from "../../../components/Button/ButtonDefault";
import JaktområdeDefault from "../../../components/Jaktomrade/Jaktomrade";
import RenderQueryResult from "../../../components/RenderQuery/RenderQueryResult";
import AppDataContext from "../../../contexts/AppDataContext/DataProvider";
import AuthContext from "../../../contexts/AuthContext/AuthProvider";
import getAppInfo from "../../../helpers/getAppInfo";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import Lenker from "./Lenker";

const emailIcon = <FontAwesomeIcon icon={faEnvelope} />;
const phoneIcon = <FontAwesomeIcon icon={faPhone} />;
const userIcon = <FontAwesomeIcon icon={faCircleUser} />;
const editIcon = <FontAwesomeIcon icon={faPencil} />;

export default function (): JSX.Element {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const appData = useContext(AppDataContext);
  const storage = useContext(StorageContext);
  const jegeropplysninger = appData.jegeropplysninger;
  const hasJaktomrader = appData.jaktområder.data && appData.jaktområder.data.length > 0;
  const showAddFavoritesAlert = useStateful<boolean>(false);
  const appInfo = getAppInfo();
  const ui = useContext(UIContext);

  const sortedJaktomrader = appData.jaktområder.data?.sort((a, b) => {
    if (a?.SistOppdatert && b?.SistOppdatert) {
      return a.SistOppdatert <= b.SistOppdatert ? 1 : -1;
    } else {
      return 0;
    }
  });

  const handleLogout = async () => {
    await auth.handleLogout();
    navigate("/");
  };

  const logoutDialog = useConfirmationDialog({
    title: t("pages.logout.confirm"),
    confirmButtonText: t("pages.logout.confirmButton" || t("actions.confirm")),
    onConfirm: handleLogout,
  });

  useEffect(() => {
    showAddFavoritesAlert.set(
      storage?.get<AlertState>({ name: "InfoAlert", id: "addFavoritesInfo" })?.data.show ?? true,
    );
  }, []);

  const områdeEntry = (områdeEntry: Favorittområde | Område) => {
    let _områdeEntry = områdeEntry;
    return (
      <JaktområdeDefault
        områdeEntry={_områdeEntry}
        selectable={false}
        onClick={async () => {
          navigate("/minside/jaktomrade", { state: { jaktomrade: områdeEntry } });
        }}
      />
    );
  };

  const globalNotifications = ui.getGlobalNotifications();

  return (
    <>
      {!hasJaktomrader && (
        <InfoAlert
          canClose
          id="addFavoritesInfo"
          onClose={() => {
            showAddFavoritesAlert.set(false);
          }}
        >
          {t("pages.minSide.addFavoritesReminder")}
        </InfoAlert>
      )}

      {globalNotifications && (
        <div className="flex flex-col items-center justify-center p-2 gap-2">
          {globalNotifications.map((notification) => (
            <WarningAlert key={notification.message}>{notification.message}</WarningAlert>
          ))}
        </div>
      )}

      <Card className="flex-wrap bg-md-greengray m-8 p-2 break-all grid grid-cols-8">
        <div className="col-span-7">
          <Typography variant="small">
            {t("form.fields.jegernummer.label")} [{jegeropplysninger.data?.Jegernummer}]
          </Typography>
          <RenderQueryResult query={jegeropplysninger}>
            <List>
              <ListItem>
                <ListItemPrefix>{userIcon}</ListItemPrefix>
                {jegeropplysninger.data?.Navn}
              </ListItem>
              <ListItem>
                <ListItemPrefix>{phoneIcon}</ListItemPrefix>
                {jegeropplysninger.data?.Telefonnummer}
              </ListItem>
              <ListItem>
                <ListItemPrefix>{emailIcon}</ListItemPrefix>
                {jegeropplysninger.data?.Epost}
              </ListItem>
            </List>
          </RenderQueryResult>
        </div>
        <div className="flex flex-col items-end col-span-1">
          <IconButton
            className="bg-md-lysegronn"
            onClick={async () => navigate("/minside/jegeropplysninger")}
          >
            {editIcon}
          </IconButton>
        </div>
      </Card>

      <Card className="flex flex-col m-8 p-2 bg-md-greengray">
        <div className="flex flex-row justify-between">
          <Typography variant="h6">{t("pages.minSide.mineJaktområder.title")}</Typography>
          <div className="flex flex-row items-center  col-span-1">
            {!hasJaktomrader && showAddFavoritesAlert.value && (
              <Alert
                severity="info"
                icon={false}
                onClose={undefined}
                className="bg-md-info rounded p-2 pt-0 pb-0 -mt-2 mr-2"
              >
                {t("pages.minSide.addFavoritesPointer")}
              </Alert>
            )}
            <IconButton
              className="bg-md-lysegronn mb-2 p-0"
              onClick={async () => navigate("/minside/jaktomrader")}
            >
              <Add sx={{ fontSize: 38 }} />
            </IconButton>
          </div>
        </div>
        <RenderQueryResult query={appData.jaktområder}>
          <div className="w-full overflow-y-auto rounded-lg">
            {sortedJaktomrader && sortedJaktomrader.length > 0
              ? sortedJaktomrader.map((o, i) => (
                  <div key={`${o.Områdekode}-${i}`}>{områdeEntry(o)}</div>
                ))
              : t("pages.minSide.mineJaktområder.leggTilOmrader")}
          </div>
        </RenderQueryResult>
      </Card>

      <Lenker />

      <Typography className="mt-5 text-center gap-2" variant="small">
        Sett og skutt {appInfo.version}
        {import.meta.env.MODE !== "prod" && (
          <span className="text-red-900">
            ({import.meta.env.MODE}) <br />
          </span>
        )}
      </Typography>

      <div className="text-center mt-10 mb-5">
        <ButtonDefault
          id="Logout"
          label={t("actions.logout")}
          variant={ButtonVariant.PrimarySquareish}
          icon={<Logout />}
          className="text-base font-light px-4"
          containerClassName="flex"
          onClick={() => logoutDialog.show()}
        />
      </div>
      {logoutDialog.element}
    </>
  );
}
