import { DATE_MAX } from "~/src/helpers/time";

export type Notification = {
  validFrom: Date;
  validTo?: Date;
  message: string;
  onClose?: () => void;
};

export const isNotificationValid = (notification: Notification) => {
  const now = new Date();

  const nowIsAfterStart = notification.validFrom <= now;
  const nowIsBeforeEnd = now <= (notification.validTo ?? DATE_MAX);

  return nowIsAfterStart && nowIsBeforeEnd;
};
