import { zodResolver } from "@hookform/resolvers/zod";
import { Card, Typography } from "@material-tailwind/react";
import { useQueryClient } from "@tanstack/react-query";
import { TFunction } from "i18next";
import { useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { ApiError } from "~/src/api/types";
import WarningAlert from "~/src/components/Alerts/WarningAlert";
import UIContext from "~/src/contexts/UIContext/UIProvider";
import { isLocalOrPrivateHost } from "~/src/helpers/network";
import { useDialog } from "~/src/hooks/useDialog";
import { appConfig } from "../../appConfig";
import LogoSos from "../../assets/img/logo-sos.svg?react";
import ButtonDefault from "../../components/Button/ButtonDefault";
import Input from "../../components/Form/Input/Input";
import ExternalLink from "../../components/Links/ExternalLink";
import AuthContext, { Credentials } from "../../contexts/AuthContext/AuthProvider";
import getAppInfo from "../../helpers/getAppInfo";
import { useStateful } from "../../hooks/useStateful";

export const loginErrorMessage = (e: ApiError, t: TFunction) => {
  let title = t("actions.error");
  let message = e.message || t("errors.unexpectedError", { details: t("errors.tryAgainLater") });

  if (["MissingAuthData"].includes(e.message)) {
  } else if (e.message === "InvalidCredentials") {
    title = t("pages.login.validation.invalidCredentials.title");
    message = t("pages.login.validation.invalidCredentials");
  } else if (e.statusCode === -1) {
    title = t("actions.error");
    message = e.message;
  }

  return { message: message, title: title };
};
const envBasedBGColor = () => {
  if (isLocalOrPrivateHost() && import.meta.env.MODE === "dev") {
    return "bg-yellow-200";
  } else if (import.meta.env.MODE === "dev") {
    return "bg-green-100";
  } else if (import.meta.env.MODE === "test") {
    return "bg-yellow-500";
  } else {
    return "bg-white";
  }
};

export default function Login(): JSX.Element {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const isLoading = useStateful(false);
  const { t } = useTranslation();
  const appInfo = getAppInfo();
  const loginErrorDialog = useDialog();
  const isUgyldigInfo = useStateful(false);
  const queryClient = useQueryClient();
  const ui = useContext(UIContext);

  const onlyNumbers = new RegExp(/^[0-9]*$/);
  const loginValidation = z
    .object({
      Jegernummer: z
        .string({ message: t("form.fields.jegernummer.validationError") })
        .min(1, { message: t("form.fields.jegernummer.validationError") })
        .max(8, { message: t("form.fields.jegernummer.validationError") })
        .regex(onlyNumbers, t("form.errors.shouldBeNumber")),
      Fodselsdato: z
        .string({ message: t("form.fields.fodselsdato.validationError") })
        .min(6, { message: t("form.fields.fodselsdato.validationError") })
        .max(6, { message: t("form.fields.fodselsdato.validationError") })
        .regex(onlyNumbers, t("form.errors.shouldBeNumber")),
    })
    .refine(
      (data) => {
        return parseInt(data.Jegernummer) > 0;
      },
      {
        path: ["Jegernummer"],
        message: t("form.fields.jegernummer.validationError"),
      },
    );

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm<Credentials>({
    resolver: zodResolver(loginValidation),
    mode: "onBlur",
  });

  const onSubmit: SubmitHandler<Credentials> = async (credentials) => {
    isUgyldigInfo.set(false);
    isLoading.set(true);
    auth
      .handleLogin(credentials)
      .then(() => {
        queryClient.invalidateQueries().then(() => queryClient.refetchQueries());
        navigate("/minside");
      })
      .catch((e: ApiError) => {
        // biome-ignore lint: må bruke any her for å få tak i errors
        const errorData: any = e.axiosError?.response?.data;
        if (errorData && "errors" in errorData) {
          if (errorData.errors && "Jegernummer" in errorData.errors) {
            setError("Jegernummer", e);
          }
          if (errorData.errors && "Fodselsdato" in errorData.errors) {
            setError("Fodselsdato", e);
          }
        } else {
          loginErrorDialog.show(loginErrorMessage(e, t));
        }
      })
      .finally(() => isLoading.set(false));
  };

  const globalNotifications = ui.getGlobalNotifications();

  return (
    <Card
      className={`h-screen flex flex-col items-center justify-center ${envBasedBGColor()} rounded-none`}
      shadow={false}
    >
      {import.meta.env.MODE !== "prod" && (
        <div className="text-red-900 bold text-3xl font-extrabold">
          {import.meta.env.MODE.toUpperCase()}
        </div>
      )}
      <div className="h-14 w-16 mb-4">
        <LogoSos className="h-14 w-16" />
      </div>
      <Typography variant="h4" className="mb-3">
        {t("app.name")}
      </Typography>
      <Typography variant="lead" className="text-center font-normal mb-5">
        {t("app.description")}
      </Typography>
      {globalNotifications && (
        <div className="flex flex-col items-center justify-center p-2 gap-2">
          {globalNotifications.map((notification) => (
            <WarningAlert key={notification.message}>{notification.message}</WarningAlert>
          ))}
        </div>
      )}
      <form className="flex-col flex py-5 space-y-5 w-[280px]" onSubmit={handleSubmit(onSubmit)}>
        <Input
          id="loginPageJegernummerInput"
          label={t("form.fields.jegernummer.label")}
          type="string"
          inputMode="numeric"
          step={1}
          errorMessage={errors.Jegernummer?.message}
          helpText={t("form.fields.jegernummer.helpText")}
          maxLength={8}
          {...register("Jegernummer")}
          onChange={(e) => {
            setError("Jegernummer", {});
            setValue("Jegernummer", e.target.value);
          }}
        />
        <Input
          id="loginPagePasswordInput"
          label={t("form.fields.fodselsdato.label")}
          type="string"
          inputMode="numeric"
          step={1}
          errorMessage={errors.Fodselsdato?.message}
          helpText={t("form.fields.fodselsdato.helpText")}
          maxLength={6}
          {...register("Fodselsdato")}
          onChange={(e) => {
            setError("Fodselsdato", {});
            setValue("Fodselsdato", e.target.value);
          }}
        />
        <ButtonDefault
          id="loginPageSubmitButton"
          type="submit"
          fullWidth={true}
          loading={isLoading.value}
          label={t("actions.login")}
        />
      </form>

      <Typography className="mt-5 text-center mb-2" variant="small">
        Sett og skutt {appInfo.version}
      </Typography>
      <ExternalLink
        url={appConfig.urls.personvernerklaering}
        text={t("pages.minSide.lenker.personvern")}
      />
      {loginErrorDialog.element}
    </Card>
  );
}
