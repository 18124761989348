import { UTCDate } from "@date-fns/utc";
import { format, parse, parseISO } from "date-fns";
import { dateFormatISO } from "~/src/components/CalendarPicker/CalendarConstants";
import currentLocale from "./locales";

export const DATE_MAX = new Date(8.64e15);
export const DATE_MIN = new Date(-8.64e15);

const browserCompatibleParse = (date: string | null | undefined): Date => {
  // see: https://github.com/date-fns/date-fns/issues/2130#issuecomment-827836812
  return parse("", "", date || "");
};

export const formatDmy = (date: string | null | undefined) => {
  if (!date) {
    return date;
  }
  format(browserCompatibleParse(date), "dd.MM.yyyy", { locale: currentLocale() });
};

export function ticksToDate(ticks: number): UTCDate {
  return new UTCDate(new Date("0001-01-01T00:00:00Z").getTime() + ticks / 1e4);
}

export function toStringWithFullMonth(date: string | null | undefined): string | null | undefined {
  if (!date) {
    return date;
  }
  return format(browserCompatibleParse(date), "d. MMM", { locale: currentLocale() });
}

export function formatISOWithoutTimestamp(
  date: Date | string | null | undefined,
): string | null | undefined {
  if (!date) {
    return date;
  }
  let _date = date;
  if (typeof date === "string") {
    _date = parseISO(date);
  }
  return format(_date, dateFormatISO);
}
