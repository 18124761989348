import { parse } from "date-fns";
import {
  dateFormatISO,
  dateTimeFormatISO,
} from "~/src/components/CalendarPicker/CalendarConstants";
import { Notification } from "~/src/contexts/UIContext/Notification";
import JaktdataApi from "./api/jaktdataApi";
import { ApiAuthProvider } from "./contexts/AuthContext/ApiAuthProvider";

export const jaktdataApi = new JaktdataApi();
export const authProvider = new ApiAuthProvider(jaktdataApi);
const ref = new Date();
export const notifications: Notification[] = [
  {
    validFrom: parse("2024-10-24", dateFormatISO, ref),
    validTo: parse("2024-10-28T06:00:00", dateTimeFormatISO, ref),
    message:
      "Fredag 25. oktober fra kl. 16:00 og til senest søndag kveld utføres det vedlikehold på nettverket som medfører nedetid på Hjorteviltregisteret. " +
      "I dette tidsrommet vil ikke jaktdata bli overført til Hjorteviltregisteret, og vi anbefaler dermed at Sett og skutt brukes i offline-modus.",
  },
];
