import { format } from "date-fns";
import { dateFormatISO } from "~/src/components/CalendarPicker/CalendarConstants";
import { AlderType, ArtType, KjønnType } from "~/src/helpers/types";
import { JaktdagResponse } from "../../../../api/types";
import { Terreng } from "../../types";

export const skuttHjortTypes = [
  {
    alder: AlderType.Voksen,
    kjønn: KjønnType.Hann,
    terreng: Terreng.Innmark,
    key: "SkuttBukkToOgHalvtÅrPlussInnmark",
  },
  {
    alder: AlderType.Voksen,
    kjønn: KjønnType.Hunn,
    terreng: Terreng.Innmark,
    key: "SkuttKolleToOgHalvtÅrPlussInnmark",
  },
  {
    alder: AlderType.Ungdyr,
    kjønn: KjønnType.Hann,
    terreng: Terreng.Innmark,
    key: "SkuttBukkEttOgHalvtÅrInnmark",
  },
  {
    alder: AlderType.Ungdyr,
    kjønn: KjønnType.Hunn,
    terreng: Terreng.Innmark,
    key: "SkuttKolleEttOgHalvtÅrInnmark",
  },
  {
    alder: AlderType.Kalv,
    kjønn: KjønnType.Hann,
    terreng: Terreng.Innmark,
    key: "SkuttHannKalvInnmark",
  },
  {
    alder: AlderType.Kalv,
    kjønn: KjønnType.Hunn,
    terreng: Terreng.Innmark,
    key: "SkuttHunnKalvInnmark",
  },
  {
    alder: AlderType.Voksen,
    kjønn: KjønnType.Hann,
    terreng: Terreng.Utmark,
    key: "SkuttBukkToOgHalvtÅrPluss",
  },
  {
    alder: AlderType.Voksen,
    kjønn: KjønnType.Hunn,
    terreng: Terreng.Utmark,
    key: "SkuttKolleToOgHalvtÅrPluss",
  },
  {
    alder: AlderType.Ungdyr,
    kjønn: KjønnType.Hann,
    terreng: Terreng.Utmark,
    key: "SkuttBukkEttOgHalvtÅr",
  },
  {
    alder: AlderType.Ungdyr,
    kjønn: KjønnType.Hunn,
    terreng: Terreng.Utmark,
    key: "SkuttKolleEttOgHalvtÅr",
  },
  {
    alder: AlderType.Kalv,
    kjønn: KjønnType.Hann,
    terreng: Terreng.Utmark,
    key: "SkuttHannKalv",
  },
  {
    alder: AlderType.Kalv,
    kjønn: KjønnType.Hunn,
    terreng: Terreng.Utmark,
    key: "SkuttHunnKalv",
  },
];

export const defaultHjortInnmark = {
  // innmark
  AntallJegereInnmark: 0,
  AntallTimerJaktetInnmark: 0,
  SettSpissbukkInnmark: 0,
  SettBukkInnmark: 0,
  SettKolleInnmark: 0,
  SettKalvInnmark: 0,
  SettUkjenteInnmark: 0,
  // skutt
  SkuttBukkToOgHalvtÅrPlussInnmark: 0,
  SkuttBukkEttOgHalvtÅrInnmark: 0,
  SkuttKolleToOgHalvtÅrPlussInnmark: 0,
  SkuttKolleEttOgHalvtÅrInnmark: 0,
  SkuttHannKalvInnmark: 0,
  SkuttHunnKalvInnmark: 0,
};

export const defaultHjortUtmark = {
  // utmark
  AntallJegere: 0,
  AntallTimerJaktet: 0,
  SettSpissbukk: 0,
  SettOkseEllerBukk: 0,
  SettKyrUtenKalvEllerKolle: 0,
  SettKalverAlene: 0,
  SettUkjente: 0,
  // skutt
  SkuttBukkToOgHalvtÅrPluss: 0,
  SkuttBukkEttOgHalvtÅr: 0,
  SkuttKolleToOgHalvtÅrPluss: 0,
  SkuttKolleEttOgHalvtÅr: 0,
  SkuttHannKalv: 0,
  SkuttHunnKalv: 0,
};

export const defaultHjort: JaktdagRegistrationDataHjort = {
  ...{
    Uri: "",
    Dato: format(new Date(), dateFormatISO),
    Jaktfelt: "",
    Id: "",
    Art: ArtType.Hjort || "",
    jaktomradeNavn: "",
  },
  ...defaultHjortInnmark,
  ...defaultHjortUtmark,
};

export const settHjortInnmarkDataKeys: (keyof SettHjortInnmark)[] = [
  "SettSpissbukkInnmark",
  "SettBukkInnmark",
  "SettKolleInnmark",
  "SettKalvInnmark",
  "SettUkjenteInnmark",
];

export const settHjortUtmarkDataKeys: (keyof SettHjortUtmark)[] = [
  "SettSpissbukk",
  "SettOkseEllerBukk",
  "SettKyrUtenKalvEllerKolle",
  "SettKalverAlene",
  "SettUkjente",
];

export const skuttHjortInnmarkDataKeys: (keyof SkuttDataHjortInnmark)[] = [
  "SkuttBukkToOgHalvtÅrPlussInnmark",
  "SkuttBukkEttOgHalvtÅrInnmark",
  "SkuttKolleToOgHalvtÅrPlussInnmark",
  "SkuttKolleEttOgHalvtÅrInnmark",
  "SkuttHannKalvInnmark",
  "SkuttHunnKalvInnmark",
];

export const skuttHjortUtmarkDataKeys: (keyof SkuttDataHjortUtmark)[] = [
  "SkuttBukkToOgHalvtÅrPluss",
  "SkuttBukkEttOgHalvtÅr",
  "SkuttKolleToOgHalvtÅrPluss",
  "SkuttKolleEttOgHalvtÅr",
  "SkuttHannKalv",
  "SkuttHunnKalv",
];

export type SettHjortInnmark = {
  SettSpissbukkInnmark: number;
  SettBukkInnmark: number;
  SettKolleInnmark: number;
  SettKalvInnmark: number;
  SettUkjenteInnmark: number;
};

export type SettHjortUtmark = {
  SettSpissbukk: number;
  SettOkseEllerBukk: number;
  SettKyrUtenKalvEllerKolle: number;
  SettKalverAlene: number;
  SettUkjente: number;
};

export type SkuttDataHjortInnmark = {
  SkuttBukkToOgHalvtÅrPlussInnmark: number;
  SkuttBukkEttOgHalvtÅrInnmark: number;
  SkuttKolleToOgHalvtÅrPlussInnmark: number;
  SkuttKolleEttOgHalvtÅrInnmark: number;
  SkuttHannKalvInnmark: number;
  SkuttHunnKalvInnmark: number;
};

export type SkuttDataHjortUtmark = {
  SkuttBukkToOgHalvtÅrPluss: number;
  SkuttBukkEttOgHalvtÅr: number;
  SkuttKolleToOgHalvtÅrPluss: number;
  SkuttKolleEttOgHalvtÅr: number;
  SkuttHannKalv: number;
  SkuttHunnKalv: number;
};

export type JaktdagRegistrationDataHjort = {
  jaktomradeNavn: string;
} & SkuttDataHjortUtmark &
  SkuttDataHjortInnmark &
  JaktdagResponse;

export type SumSkuttHjort = {
  skuttBukker: number;
  skuttSpissbukker: number;
  skuttKoller: number;
  skuttKalver: number;
  sumSkutt: number;
};

export type SumSettHjort = {
  settBukker: number;
  settSpissbukker: number;
  settKoller: number;
  settKalver: number;
  settUkjente: number;
  sumSett: number;
};

export type SumSettOgSkuttHjortTerreng = {
  utmark: { sett: SumSettHjort; skutt: SumSkuttHjort };
  innmark: { sett: SumSettHjort; skutt: SumSkuttHjort };
};
